<!--
 * @Author: wxb
 * @Date: 2021-07-22 23:56:00
 * @LastEditTime: 2022-04-12 11:40:47
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\seniorSearch.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='2'></headerNav>
    <div class="product-compare">
      <div class="product-compare-btn"
           @click="handleComparePanelShow"
           :style="{right:showCompare?'255px':'0px'}">
        机构对比
        <i v-if="showCompare"
           class="el-icon-s-unfold"></i>
        <i v-else
           class="el-icon-s-fold"></i>
      </div>
      <div class="product-compare-container"
           v-if="showCompare">
        <div class="product-compare-title">
          <span class="title-icon">
            <svg class="icon"
                 aria-hidden="true">
              <use xlink:href="#icon-hechabidui"></use>
            </svg>
          </span>
          <span class="title-label">机构对比</span>
        </div>
        <div class="product-compare-body">
          <div class="compare-goodslist"
               v-for="(t,index) in selectCompareList"
               :key="index">
            <div class="compare-goodslist-item">
              <div :title="t.companyName">{{t.mingCh}}</div>
            </div>
            <div class="compare-goodslist-btn">
              <i class="el-icon-close"
                 @click="delContrastList(index)"></i>
            </div>
          </div>
        </div>
        <div class="product-compare-btns">
          <div class="btn-compare"
               @click="startCompare">开始对比</div>
          <div class="btn-clearAll"
               @click="clearCompareInfo">清空对比栏</div>
        </div>
      </div>
    </div>

    <div class="company">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/orgSearch'}">查机构</el-breadcrumb-item>
        <el-breadcrumb-item>高级搜索</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="combo-search-conttainer">
        <!-- 基本信息 -->
        <el-row :gutter="20"
                type="flex"
                justify="center"
                align="middle"
                class="mb-5 box-border">
          <el-col :span="3"
                  class="combo-title">按基本信息查询：</el-col>
          <el-col :span="21">
            <el-row :gutter="20"
                    class="mb-5">
              <el-col :span="12">
                <el-input v-model="searchForm.companyName"
                          placeholder="输入企业名称查询"
                          clearable></el-input>
              </el-col>
              <el-col :span="12">
                <el-select v-model="searchForm.registerAddr"
                           filterable
                           remote
                           clearable
                           reserve-keyword
                           placeholder="输入企业注册属地查询"
                           :loading="loading">
                  <el-option v-for="(item,i) in selectOptionsAddr"
                             :key="i"
                             :label="item"
                             :value="item"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="20"
                    class="mb-5">
              <el-col :span="12">
                <el-input v-model="searchForm.tongYShHXYDM"
                          clearable
                          placeholder="输入统一社会信用代码查询"></el-input>
              </el-col>
              <el-col :span="12">
                <el-input v-model="searchForm.legalPerson"
                          clearable
                          placeholder="输入企业法人代表查询"></el-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!-- 资质信息 -->
        <el-row :gutter="20"
                type="flex"
                justify="center"
                align="middle">
          <el-col :span="3"
                  class="combo-title">按具备资质查询：</el-col>
          <el-col :span="19">
            <el-row :gutter="20"
                    class="mb-5">
              <el-col :span="12">
                <el-select v-model="qualifyInfoId"
                           clearable
                           filterable
                           @change="initQualifyLevel"
                           placeholder="输入关键词快速选择资质">
                  <el-option v-for="item in qualifyOptions"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select v-model="strQualifyLevel"
                           filterable
                           remote
                           clearable
                           reserve-keyword
                           placeholder="请先选择资质后选择资质等级"
                           :loading="loading">
                  <el-option v-for="(item,i) in selectOptionsLevel"
                             :key="i"
                             :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="2">
            <el-button type="primary"
                       @click="handleClickAddSceen">加入筛选</el-button>
          </el-col>
        </el-row>
        <el-row :span="20"
                type="flex"
                justify="center"
                align="middle"
                class="box-border">
          <el-col :span="20"
                  :offset="3">
            <div class="nconditions-wrap clearfix"
                 v-for="(item,index) in qualifySelectLst"
                 :key="index">
              <div class="nconditions fl">
                <span v-if="item.qualifyLevel">{{item.qualifyName}}-{{item.qualifyLevel}} </span>
                <span v-else>{{item.qualifyName}} </span>
                <a href="javascript:;"
                   @click="handleClickDelQualify(index)">×</a>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 展会信息 -->
        <el-row :gutter="20"
                type="flex"
                justify="center"
                align="middle"
                class="mb-5 box-border">
          <el-col :span="3"
                  class="combo-title">按参加展会信息查询：</el-col>
          <el-col :span="21">
            <el-row :gutter="20"
                    class="mb-5">
              <el-col :span="12">
                <el-select v-model="searchForm.exhibitionName"
                           filterable
                           clearable
                           placeholder="请选择展会名称"
                           @change="initExhibitionYearList">
                  <el-option v-for="item in options4ExhibitionName"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select v-model="searchForm.exhibitionYear"
                           filterable
                           clearable
                           placeholder="请先选择展会，后选择展会年份查询"
                           @change="initExhibitionYearList">
                  <el-option v-for="item in options4exhibitionYear"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!-- 业绩信息 -->
        <el-row :gutter="20"
                type="flex"
                justify="center"
                align="middle"
                class="mb-5 box-border">
          <el-col :span="3"
                  class="combo-title">按业绩查询：</el-col>
          <el-col :span="21">
            <el-row :gutter="20"
                    class="mb-5">
              <el-col :span="12">
                <el-input placeholder="输入项目名称查询"
                          v-model="searchForm.projectName"
                          clearable></el-input>
              </el-col>
              <el-col :span="12">
                <el-select v-model="searchForm.achievementCost"
                           clearable
                           placeholder="请选择项目金额区间">
                  <el-option v-for="item in options4money"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="20"
                align="middle">
          <el-col :span="24"
                  class="text-center">
            <el-button type="primary"
                       @click="searchCompany">高级搜索</el-button>
            <el-button type="warning"
                       @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="result-count">
        符合条件的机构共{{allTotal}}家
      </div>
      <div class="company-list">
        <div class="content">
          <div v-show="!noData && canSee">
            <ul class="company-block-list clearfix">
              <li class="item"
                  v-for="(item,index) in companyList"
                  :key="index">
                <div class="item-con">
                  <div @click="handleItemClick(index)">
                    <div class="img">
                      <img v-if="item.logoPath"
                           :src="`/api/company/fileManagementNoLogin/preview?fileName=1.png&filePath=${item.logoPath}`" />
                      <div v-else
                           class="nologo"
                           :class="{'bg-1':index%4===1,'bg-2':index%4===2,'bg-3':index%4===3,'bg-4':index%4===0}">{{item.strLogo}}</div>
                    </div>
                    <div class="company-name"
                         :title="item.mingCh">{{item.mingCh}}</div>
                    <div class="company-label">
                      <span v-for="(v,i) in item.labelList"
                            :key="i"
                            class="label"
                            :class="v.class"
                            :title="v.info">{{v.label}}</span>
                    </div>
                    <div class="company-capital"
                         title="资质">
                      <div class="company-capital-icon">
                        <svg class="icon"
                             aria-hidden="true">
                          <use xlink:href="#icon-zizhi"></use>
                        </svg>
                      </div>
                      <div class="company-capital-content">
                        {{item.qualificationsNum}}项
                      </div>
                    </div>
                    <div class="company-link"
                         title="联系人">
                      <div class="company-link-icon">
                        <svg class="icon"
                             aria-hidden="true">
                          <use xlink:href="#icon-lianxiren"></use>
                        </svg>
                      </div>
                      <div class="company-link-content">
                        {{item.contactName}}
                      </div>
                    </div>
                    <div class="company-tel"
                         title="联系方式">
                      <div class="company-tel-icon">
                        <svg class="icon"
                             aria-hidden="true">
                          <use xlink:href="#icon-lianxidianhua"></use>
                        </svg>
                      </div>
                      <div class="company-tel-content">
                        <span>{{item.telephone | formatTel(memberType)}}</span>
                        <svg class="icon"
                             aria-hidden="true"
                             v-show="item.telephone">
                          <use xlink:href="#icon-VIP"></use>
                        </svg>
                      </div>
                    </div>
                    <div class="company-address"
                         title="注册地址">
                      <div class="company-address-icon">
                        <svg class="icon"
                             aria-hidden="true">
                          <use xlink:href="#icon-dizhi"></use>
                        </svg>
                      </div>
                      <div class="company-address-content">
                        {{item.address}}
                      </div>
                    </div>
                    <!-- <div class="company-intro">{{item.companyInfo}}</div> -->
                  </div>
                  <div class="goodslist-tools">
                    <div class="compare"
                         @click="handleCompareChecked(index)"
                         :class="{'active':selectCompareList.some(e => e.id === item.id)}">
                      <svg class="icon"
                           aria-hidden="true">
                        <use v-if="selectCompareList.some(e => e.id === item.id)"
                             xlink:href="#icon-duihao"></use>
                        <use v-else
                             xlink:href="#icon-fangkuang"></use>
                      </svg>
                      <span>对比</span>
                    </div>
                    <div class="collection"
                         :class="{'active':item.action==='add'}"
                         @click="handleCollect(item,index)">
                      <svg class="icon"
                           aria-hidden="true">
                        <use v-if="item.action==='add'"
                             xlink:href="#icon-shoucang2"></use>
                        <use v-else
                             xlink:href="#icon-shoucang1"></use>
                      </svg>
                      <span>收藏</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="content-page">
              <el-pagination background
                             layout="prev, slot, next"
                             :total="companyCnt"
                             :current-page.sync="companyCurr"
                             :page-size="18"
                             @current-change="handleCurrentChange">
                <span class="current-page">{{companyCurr}}</span>
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <noData tips="哎呀，未查询到相关机构信息"
            v-show="noData"></noData>
    <viporlogin bgStr="5"
                :type="viporlogin"
                v-show="!canSee"></viporlogin>
    <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { authenticate } from '@/utils/authenticate'
import { getToken, setUserInfo } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import dialogviporlogin from '@/components/dialogviporlogin'
import {
  getCompanyList4delivery,
  getRegAddrList,
  getInfoTree,
  getInfoList,
  getExhibitionNameList,
  getExhibitionYearsByName
} from '@/api/organization'
import { collection } from '@/api/collect'
export default {
  name: 'seniorSearch',
  components: {
    noData,
    viporlogin,
    dialogviporlogin,
    headerNav,
    footerNav
  },
  data() {
    return {
      noData: false,
      canSee: true,
      viporlogin: 'login',
      companyList: [],
      companyCnt: 1,
      companyCurr: 1,
      selectCompareList: [],
      showCompare: false,
      searchForm: {
        keywords: '', // 关键词
        companyName: '', // 机构名称
        registerAddr: '', // 注册属地
        tongYShHXYDM: '', // 统一社会信用代码
        legalPerson: '', // 法人名称
        qualifyArr: [], // 资质信息列表
        exhibitionName: '', // 展会名称
        exhibitionYear: '', // 展会年份
        projectName: '', // 项目名称
        achievementCost: '', // 业绩金额区间
        start: 0,
        limit: 18
      },
      // 高级查询相关信息
      qualifyInfoId: '',
      strQualifyLevel: '',
      loading: false,
      // 企业注册地址
      selectOptionsAddr: [],
      // 资质选项列表
      qualifyOptions: [],
      // 资质名称补全下拉列表
      selectOptionsQua: [],
      // 资质等级下拉列表
      selectOptionsLevel: [],
      options4money: [
        {
          value: 1,
          label: '1000万以下'
        },
        {
          value: 2,
          label: '1000-3000万'
        },
        {
          value: 3,
          label: '3000万以上'
        }
      ],
      qualifySelectLst: [],
      // 展会名称列表
      options4ExhibitionName: [],
      // 展会年份列表
      options4exhibitionYear: [],
      allTotal: ''
    }
  },
  methods: {
    // 机构对比
    handleCompareChecked(index) {
      // 获取能否添加对比权限
      const authObj = authenticate('compareOrg', this.memberType)
      if (authObj.flag) {
        const id = this.companyList[index].id
        const idx = this.selectCompareList.findIndex((value, index, arr) => value.id === id)
        if (idx !== -1) {
          this.selectCompareList.splice(idx, 1)
        } else {
          if (this.selectCompareList.length >= 4) {
            this.$message({
              showClose: true,
              message: '最多只能对比四个机构',
              type: 'warning'
            })
          } else {
            this.selectCompareList.push(this.companyList[index])
            this.companyList[index].checked = true
            this.showCompare = true
          }
        }
      } else {
        this.$refs.dialogviporlogin.handleShow(authObj.msg)
      }
    },
    // 机构对比框显示隐藏
    handleComparePanelShow() {
      this.showCompare = !this.showCompare
    },
    // 开始对比
    startCompare() {
      if (this.selectCompareList.length > 0) {
        sessionStorage.setItem('compnayCompareList', JSON.stringify(this.selectCompareList))
        const routeData = this.$router.resolve({
          name: 'compareOrg'
        })
        window.open(routeData.href, '_blank')
      } else {
        this.$message({
          showClose: true,
          message: '请选择对比的机构',
          type: 'warning'
        })
      }
    },
    // 清空对比信息
    clearCompareInfo() {
      for (const item of this.selectCompareList) {
        const id = item.id
        const idx = this.companyList.findIndex((value, index, arr) => value.id === id)
        if (idx !== -1) {
          this.companyList[idx].checked = false
        }
      }
      this.selectCompareList = []
    },
    // 删除对比机构
    delContrastList(index) {
      const id = this.selectCompareList[index].id
      this.selectCompareList.splice(index, 1)
      const idx = this.companyList.findIndex((value, index, arr) => value.id === id)
      if (idx !== -1) {
        this.companyList[idx].checked = false
      }
    },
    // 跳转到机构详情页面
    handleItemClick(index) {
      const id = this.companyList[index].id
      const routeData = this.$router.resolve({
        name: 'orgDetail',
        params: { id }
      })
      window.open(routeData.href, '_blank')
    },
    // 处理分页
    handleCurrentChange(val) {
      // 如果没有登录则不让点击
      if (getToken()) {
        this.searchForm.start = (val - 1) * this.searchForm.limit
        this.getCompanyList()
      } else {
        // 弹出需要登录框
        this.$refs.dialogviporlogin.handleShow('login')
        this.companyCurr = 1
      }
    },
    // 本页面的搜索按钮清空其他搜索条件只保留最外层的搜索条件
    searchCompany() {
      if (!this.canSee) {
        return
      }
      this.searchForm.start = 0
      this.companyCurr = 1
      this.getCompanyList()
    },
    getCompanyList() {
      if (getToken()) {
        this.searchForm.userId = this.userInfo.id
      } else {
        this.searchForm.userId = ''
      }
      getCompanyList4delivery(this.searchForm).then(data => {
        if (data.ok) {
          this.allTotal = data.cnt
          if (data.cnt > 0) {
            this.noData = false
          } else {
            this.noData = true
          }
          this.companyList = data.lst
          // 如果超过10页
          if (data.cnt > this.searchForm.limit * 10) {
            this.companyCnt = this.searchForm.limit * 10
          } else {
            this.companyCnt = data.cnt
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    // 收藏机构
    handleCollect(item, index) {
      if (!getToken()) {
        this.$refs.dialogviporlogin.handleShow('login')
        return false
      }
      const params = {
        collectionitemId: item.id,
        collectionType: 'org',
        collectionitemName: item.mingCh
      }
      if (item.action === 'cancle') {
        params.action = 'add'
        this.handleCollectEvent(params, index)
      } else if (item.action === 'add') {
        params.action = 'cancle'
        this.$confirm('确认取消机构收藏?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleCollectEvent(params, index)
        })
      }
    },
    handleCollectEvent(params, index) {
      collection(params).then(res => {
        if (res.ok) {
          this.companyList[index].action = params.action
          this.$forceUpdate()
          if (params.action === 'add') {
            this.$message({
              message: '收藏成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: '取消收藏成功',
              type: 'success'
            })
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      }).catch(err => { console.log(err) })
    },
    authCheck() {
      const authObj = authenticate('orgSeniorSearch', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.getCompanyList()
        this.canSee = true
        this.getSelectOptionAddr()
        this.initQualifyOptions()
        this.initExhibitionNameList()
        this.initExhibitionYearList()
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    // 获取企业注册地址
    getSelectOptionAddr() {
      getRegAddrList()
        .then(data => {
          if (data.ok) {
            this.selectOptionsAddr = []
            for (const e of data.lst) {
              this.selectOptionsAddr.push(e)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 初始化资质选项
    initQualifyOptions() {
      const params = {
        operateID: 1
      }
      getInfoTree(params).then(data => {
        if (data.ok) {
          const list = data.lst
          this.handleQualifyArr(list)
        } else {
          console.log(data.msg)
        }
      }).catch(error => {
        console.error(error)
      })
    },
    // 处理资质选项
    handleQualifyArr(list, name = '') {
      for (const item of list) {
        const label = name + '/' + item.strQualifyName
        if (item.lst) {
          this.handleQualifyArr(item.lst, label)
        } else {
          this.qualifyOptions.push({
            value: item.id,
            label
          })
        }
      }
    },
    // 改变资质初始化资质等级
    initQualifyLevel(value) {
      if (value === '') {
        this.selectOptionsLevel = []
        this.strQualifyLevel = ''
        return
      }
      const params = {
        operateID: 1,
        idArr: value,
        blFinal: 1,
        start: 0,
        limit: 18
      }
      getInfoList(params)
        .then(d => {
          if (d.ok) {
            const list = d.lst
            const selectOptionsLevel = []
            if (list) {
              for (const item of list) {
                const obj = {
                  value: item.levelID,
                  label: item.levelName
                }
                selectOptionsLevel.push(obj)
              }
            }
            this.selectOptionsLevel = selectOptionsLevel
            this.strQualifyLevel = ''
          } else {
            console.error(d.msg)
          }
        })
        .catch(e => console.error(e))
    },
    // 处理加入筛选
    handleClickAddSceen() {
      const qualifyInfoId = this.qualifyInfoId
      if (!qualifyInfoId) {
        this.$message({
          message: '请选择资质信息',
          type: 'warning',
          showClose: false
        })
        return
      }
      const strQualifyLevel = this.strQualifyLevel
      const obj4Qua = this.qualifyOptions.find(item => item.value === qualifyInfoId)
      const obj4Level = this.selectOptionsLevel.find(item => item.value === strQualifyLevel)

      const obj4qualifyArr = {
        qualifyCode: obj4Qua.value
      }
      const obj = {
        qualifyName: obj4Qua.label,
        qualifyCode: obj4Qua.value
      }
      let id = ''
      if (obj4Level) {
        obj4qualifyArr.levelCode = obj4Level.value
        obj.qualifyLevel = obj4Level.label
        obj.levelCode = obj4Level.value
        id = `${obj.qualifyCode}${obj.levelCode}`
        obj.id = id
      } else {
        id = `${obj.qualifyCode}`
        obj.id = id
      }
      const index = this.qualifySelectLst.findIndex(value => value.id === id)
      if (index === -1) {
        this.qualifySelectLst.push(obj)
        this.searchForm.qualifyArr.push(obj4qualifyArr)
        this.qualifyInfoId = ''
        this.strQualifyLevel = ''
        this.selectOptionsLevel = []
      } else {
        this.$message({
          message: '已经加入筛选',
          type: 'warning',
          showClose: false
        })
      }
    },
    // 处理删除加入的资质筛选
    handleClickDelQualify(index) {
      this.qualifySelectLst.splice(index, 1)
      this.searchForm.qualifyArr.splice(index, 1)
    },
    // 初始化展会名称
    initExhibitionNameList() {
      getExhibitionNameList().then(data => {
        if (data.ok) {
          const list = data.lst
          for (const iterator of list) {
            const obj = {
              value: iterator,
              label: iterator
            }
            this.options4ExhibitionName.push(obj)
          }
        } else {
          console.error(data.msg)
        }
      }).catch(error => {
        console.error(error)
      })
    },
    // 初始化展会年份
    initExhibitionYearList(value = '') {
      const params = {
        name: value
      }
      getExhibitionYearsByName(params).then(data => {
        if (data.ok) {
          const list = data.lst
          const options4exhibitionYear = []
          for (const iterator of list) {
            const obj = {
              value: iterator,
              label: iterator
            }
            options4exhibitionYear.push(obj)
          }
          this.options4exhibitionYear = options4exhibitionYear
        } else {
          console.error(data.msg)
        }
      }).catch(error => {
        console.error(error)
      })
    },
    // 重置搜索条件
    handleReset() {
      this.searchForm = {
        keywords: '', // 关键词
        companyName: '', // 企业名称
        registerAddr: '', // 注册属地
        tongYShHXYDM: '', // 统一社会信用代码
        legalPerson: '', // 法人名称
        qualifyArr: [], // 资质信息列表
        exhibitionName: '', // 展会名称
        exhibitionYear: '', // 展会年份
        projectName: '', // 项目名称
        achievementCost: '', // 业绩金额区间
        start: 0,
        limit: 18
      }
      this.qualifySelectLst = []
      this.qualifyInfoId = ''
      this.strQualifyLevel = ''
      this.searchCompany()
    }
  },
  filters: {
    formatTel(tel, memberType) {
      const telphone = tel || ''
      if (!telphone) {
        return telphone
      }
      if (memberType === 'vipUser') {
        return telphone
      } else {
        const length = telphone.length
        const markLength = length - 3 - 4
        let mark = ''
        for (let index = 0; index < markLength; index++) {
          mark += '*'
        }
        const newTel = telphone.substr(0, 3) + mark + telphone.substr(-4)
        return newTel
      }
    }
  },
  mounted() {
    if (getToken()) {
      if (!this.userInfo) {
        this.$store.dispatch('getUserInfo').then(info => { // 拉取user_info
          setUserInfo(info)
          const elements = {}
          for (let i = 0; i < info.elements.length; i++) {
            elements[info.elements[i].code] = true
          }
          localStorage.setItem('elements', JSON.stringify(elements))
        })
      }
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType',
      userInfo: 'userInfo'
    })
  }

}
</script>

<style lang="scss" scoped>
// 高级搜索项
.combo-search-conttainer {
  background: #fff;
  padding: 5px;
  font-size: 14px;
  .el-select {
    display: block;
  }

  .mb-5 {
    margin-top: 5px;
  }

  .box-border {
    padding-bottom: 10px;
    border-bottom: 1px dashed #dedede;
  }

  .combo-title {
    font-weight: 700;
    text-align: right;
    color: #1473e6;
    font-size: 16px;
  }

  .text-center {
    padding-top: 15px;
    text-align: center;
  }
}

.nconditions-wrap {
  margin-top: 8px;
  width: 100%;
  .nconditions {
    background-color: #e9f4fa;
    padding: 4px 10px;
    line-height: 22px;
    font-size: 14px;
    color: #999;
    span {
      line-height: 22px;
      font-size: 14px;
      color: #999;
    }
    a {
      color: #f40000;
      padding-left: 10px;
      font-size: 18px;
    }
  }
  .fl {
    float: left;
  }
}

.result-count {
  background: #f1f1f1;
  padding: 10px;
}
// 机构列表
.company {
  width: 144rem;
  margin: auto;

  .el-breadcrumb {
    height: 5.4rem;
    line-height: 5.4rem;
  }

  &-list {
    .content {
      padding: 0 12px 20px;

      .company-block-list {
        margin: 0;
        list-style: none;
        padding: 0;
        .item {
          float: left;
          width: 220px;
          height: 350px;
          margin-right: 15px;
          margin-bottom: 15px;
          background-color: #fff;
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          cursor: pointer;
          border-radius: 5px;
          color: #666;
          position: relative;
          &-con {
            padding: 30px 10px 0;
          }
          .img {
            text-align: center;

            .nologo {
              display: inline-block;
              width: 88px;
              height: 88px;
              border-radius: 50%;
              font-size: 35px;
              line-height: 88px;
              color: #fff;
              background: #84cab0;
            }

            .bg-1 {
              background: #84cab0;
            }

            .bg-2 {
              background: #d1ae9d;
            }

            .bg-3 {
              background: #8ca3cd;
            }

            .bg-4 {
              background: #7db3d9;
            }

            img {
              width: 100%;
              height: 88px;
            }
          }

          &:hover {
            box-shadow: 0 0 16px 0 #c9c9c9;
          }

          .company-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            margin-top: 10px;
          }

          .company-label {
            margin: 10px 0;
            .label {
              vertical-align: middle;
              width: 25px;
              height: 25px;
              display: inline-block;
              position: relative;
              top: 1px;
              font-size: 14px;
              border-radius: 50%;
              color: #fff;
              text-align: center;
              line-height: 25px;
              &:not(:first-child) {
                margin-left: 5px;
              }
            }
            .label-1 {
              background-color: #3d97ff;
            }

            .label-2 {
              background-color: #ffbf55;
            }

            .label-3 {
              background-color: #f66d61;
            }
          }

          .company-link {
            display: flex;
            align-content: center;
            align-items: center;
            &-icon {
              font-size: 24px;
            }
            &-content {
              padding-left: 10px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }

          .company-tel {
            display: flex;
            align-content: center;
            &-icon {
              font-size: 24px;
            }
            &-content {
              padding-left: 10px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;

              .icon {
                font-size: 24px;
              }
            }
          }

          .company-capital {
            display: flex;
            align-content: center;
            align-items: center;
            &-icon {
              font-size: 24px;
            }
            &-content {
              padding-left: 10px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }

          .company-address {
            display: flex;
            align-content: center;
            align-items: center;
            &-icon {
              font-size: 24px;
            }
            &-content {
              padding-left: 10px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }

          // .company-intro {
          //   display: -webkit-box;
          //   -webkit-box-orient: vertical;
          //   -webkit-line-clamp: 6;
          //   overflow: hidden;
          // }

          // .company-compare {
          //   border: 1px solid #edefec;
          //   padding: 2px 10px;
          //   margin-top: 5px;
          // }

          .goodslist-tools {
            position: absolute;
            width: 100%;
            bottom: 8px;
            display: flex;
            color: #999;

            & > div {
              padding-left: 15px;
              border: 1px solid #ddd;
              padding-top: 5px;
              padding-bottom: 5px;
              font-size: 14px;
            }

            .compare {
              width: 100px;
              border-right: #fff;
              // width: 203px;
              &.active {
                border: 1px solid #d81e06;
                color: #d81e06;
              }

              &.active + .collection {
                border-left: #d81e06;
              }
              span {
                position: relative;
                left: 4px;
              }

              &:hover {
                border: 1px solid #d81e06;
                color: #d81e06;
              }
            }

            .collection {
              width: 100px;
              &.active {
                border: 1px solid #d81e06;
                color: #d81e06;
              }

              &.active + .compare {
                border-right: #d81e06;
              }

              span {
                position: relative;
                left: 4px;
              }

              &:hover {
                border: 1px solid #d81e06;
                color: #d81e06;
              }
            }

            .addcart {
              flex: 1;
              color: #d81e06;
              span {
                position: relative;
                left: 4px;
                color: #d81e06;
              }

              &:hover {
                border: 1px solid #d81e06;
                color: #d81e06;
              }
            }
          }
        }
      }
    }

    .content-page {
      margin-top: 10px;
      text-align: right;
    }
  }
}
// 对比框样式
.product-compare {
  position: fixed;
  top: 202px;
  right: 0px;
  background: #fff;
  z-index: 10;
  border-radius: 5px;

  &-btn {
    z-index: 1;
    background: #fe6811;
    color: #fff;
    font-size: 18px;
    position: absolute;
    top: 64px;
    right: 0px;
    width: 42px;
    padding: 20px 10px;
    cursor: pointer;
    border-radius: 5px 0 0 5px;
    line-height: 25px;
  }

  &-container {
    width: 255px;
    border-radius: 5px;
    border: 1px solid #ddd;
    .product-compare {
      &-title {
        text-align: center;

        font-size: 22px;
        font-weight: 600;
        color: #14151a;
        padding: 10px 5px;
        border-bottom: 1px solid #ddd;
        .title {
          &-icon {
            color: #fff;
            background: #398ce2;
            border-radius: 50%;
            width: 44px;
            height: 44px;
            display: inline-block;
            font-size: 24px;
            line-height: 42px;
          }

          &-label {
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
      &-body {
        height: 160px;
        overflow: auto;
        .compare-goodslist {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ddd;
          padding: 5px 10px;
          cursor: pointer;
          &:hover {
            background: #398ce2;
            color: #fff;
            div {
              color: #fff;
            }
          }
          &-item {
            flex: 1;
            max-width: 203px;
            div {
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 5px;
              color: #666;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              line-height: 14px;
            }
          }

          &-btn {
            width: 50px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
      &-btns {
        div {
          font-size: 14px;
          height: 40px;
          width: 180px;
          text-align: center;
          line-height: 40px;
          border-radius: 5px;
          color: #fff;
          margin: 10px auto;
          cursor: pointer;
        }

        .btn {
          &-compare {
            background: #398ce2;
          }

          &-clearAll {
            background: #fe6811;
          }
        }
      }
    }
  }
}
</style>
